import React from 'react'
import { graphql } from 'gatsby'
import {
  AlternativeHero,
  Breadcrumbs,
  Container,
  Page,
  ResponsiveImage,
} from '@components'
import IconChat from '@assets/images/icon-chat.svg'
import {
  Aside,
  ContactButton,
  Content,
  Main,
} from './style'

const SolutionTemplate = ({ data }) => {
  const { excerpt, fields, frontmatter, html } = data.markdownRemark

  const meta = {
    title: `${frontmatter.name} - Rješenja | Protupožarna zaštita`,
    description: frontmatter.description || excerpt,
    image: require(`@assets/images/rjesenja/${frontmatter.image}`),
    type: 'article',
  }

  return (
    <Page
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: '/', name: 'Početna'},
          { link: '/rjesenja', name: 'Rješenja'},
          { link: `/rjesenja${fields.slug}`, name: frontmatter.name},
        ]}
      />
      <AlternativeHero
        slim
        title={frontmatter.name}
        subtitle="Naša rješenja"
      />
      <Container>
        <Main>
          <ContactButton
            href={`mailto:info@fsb-zagreb.hr?subject=Upit o rješenju ${frontmatter.name}`}
            onClick={() => trackGAEvent({
              category: 'rjesenja',
              action: 'inquiry',
              label: frontmatter.name,
              isConversion: true,
            })}
          >
            <img src={IconChat} height="28px" alt="Pošalji upit" />
            <span>
              Pošalji upit o rješenju
            </span>
          </ContactButton>
          <Content>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Content>
          <Aside>
            <ResponsiveImage
              alt={`${frontmatter.name}`}
              filename={`rjesenja/${frontmatter.image}`}
              style={{ maxWidth: '400px' }}
            />
          </Aside>
        </Main>
      </Container>
    </Page>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      html
      fields {
        slug
      }
      excerpt
      frontmatter {
        name
        description
        image
      }
    }
  }
`

export default SolutionTemplate
